.BookmarkCard {
  margin-bottom: 30px;
}

.BookmarkCard h3 {
  color: var(--color-accent);
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.Bookmarks {
  display: flex;
  flex-direction: column;
}

.Bookmarks a {
  line-height: 2;
  transition: all 0.25s;
  display: flex;
}

.BookmarkCard a:hover {
  text-decoration: underline;
  padding-left: 10px;
}

.BookmarkIcon {
  width: 20px;
  height: 20px;
  display: flex;
  margin-top: 3px;
  margin-right: 2px;
  justify-content: center;
  align-items: center;
}

.BookmarkIconSvg {
  width: 80%;
  height: 80%;
  margin-top: 2px;
  margin-left: 2px;
  object-fit: contain;
}

.CustomIcon {
  width: 90%;
  height: 90%;
  margin-top: 2px;
  object-fit: contain;
}
