.TableActions {
  display: flex;
  align-items: center;
}

.TableAction {
  width: 22px;
}

.TableAction:hover {
  cursor: pointer;
}

.Message {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  color: var(--color-primary);
  margin-bottom: 20px;
}

.Message a {
  color: var(--color-accent);
}

.Message a:hover {
  cursor: pointer;
}