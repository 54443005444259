.ThemePreview {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.ThemePreview:hover {
  cursor: pointer;
}

.ThemePreview p {
  text-transform: capitalize;
  margin: 8px 0;
  color: var(--color-primary);
  /* align-self: flex-start; */
}

.ColorsPreview {
  display: flex;
  border: 1px solid silver;
}

.ColorPreview {
  width: 50px;
  height: 50px;
}

@media (min-width: 340px) {
  .ColorPreview {
    width: 40px;
    height: 40px;
  }
}